import React from "react";
import DownloadTemplateButton from "../components/profiles/DownloadTemplateButton";
import {useProfileStore} from "../store/ProfileStore";
import TableProfiles from "../components/profiles/TableProfiles";
import SaveCVSButton from "../components/profiles/SaveCVSButton";
import ImportFileButton from "../components/profiles/ImportFileButton";
import DatesSelector from "../components/profiles/DatesSelector";

export default function ProfilesPage() {
    //profile list, reset values method and is
    const {profiles, isDatesSelected, resetValues} = useProfileStore();

    //reset values when it's show for the first time because when its downloaded and re-enter to the screen
    //the data it's stored into zustand storage
    React.useEffect(() => resetValues(), []);

    return (
        <div className={'main-section overflow-scroll scrollbar-hide'}>
            <div
                className={'bg-white flex flex-row h-[56px] rounded-md shadow-md px-4 items-center justify-between'}>

                <div className={'flex flex-row items-center space-x-4'}>
                    <h2 className="text-2xl font-bold tracking-tight">Perfiles</h2>
                </div>

                <div className={'flex flex-row items-center space-x-4'}>
                    {/*download template into downloads folder*/}
                    <DownloadTemplateButton/>
                    {/*import excel file*/}
                    <ImportFileButton/>
                </div>
            </div>

            <div className={'flex flex-col bg-white w-full p-4 rounded-md shadow-md space-y-4'}>

                <div className={'flex flex-row w-full justify-between space-x-2'}>

                    {/*start-end date selector*/}
                    <DatesSelector/>

                    {/*show save cvs button when profiles exists*/}
                    {isDatesSelected &&
                        profiles.length > 0 &&
                        <SaveCVSButton/>}
                </div>

                {/*separation line*/}
                <hr/>

                {/*show hint text to select start-end dates*/}
                {!isDatesSelected &&
                    <div className={'text-center'}>
                        <p className={'font-semibold tracking-tight text-slate-400'}>Selecciona fecha inicio y fecha
                            fin
                            para consultar los
                            datos.</p>
                    </div>}
                {/*show table profiles when use select start-end dates*/}
                {isDatesSelected &&
                    <div className={'overflow-scroll scrollbar-hide'}>
                        <TableProfiles/>
                    </div>}
            </div>

        </div>
    )
}

